import axios from 'axios';
import CONFIG from 'config';
import { setAppliedFilters, formatFilters } from 'utils/filters/filters';
import { buildQueryString, currentQueryStringToObjArrays, escapeRegExp, flattenQuery, queryWithSearchParamsOnly } from 'utils/string-mapper/string-mapper';

export const API_CASEV2_SEARCH_PENDING = 'API_CASEV2_SEARCH_PENDING';
export const API_CASEV2_SEARCH_SUCCESS = 'API_CASEV2_SEARCH_SUCCESS';
export const API_CASEV2_SEARCH_FAILURE = 'API_CASEV2_SEARCH_FAILURE';
export const SET_CASEV2_ACTIVE_FILTER_INDEX = 'SET_CASEV2_ACTIVE_FILTER_INDEX';
export const SET_CASEV2_APPLIED_FILTERS_PENDING = 'SET_CASEV2_APPLIED_FILTERS_PENDING';
export const SET_CASEV2_APPLIED_FILTERS_SUCCESS = 'SET_CASEV2_APPLIED_FILTERS_SUCCESS';
export const SET_CASEV2_APPLIED_FILTERS_FAILED = 'SET_CASEV2_APPLIED_FILTERS_FAILED';
export const CHANGE_CASEV2_SORTER = 'CHANGE_CASEV2_SORTER';
export const API_CASEV2_LOAD_MORE_PENDING = 'API_CASEV2_LOAD_MORE_PENDING';
export const API_CASEV2_LOAD_MORE_FAILURE = 'API_CASEV2_LOAD_MORE_FAILURE';
export const API_CASEV2_LOAD_MORE_SUCCESS = 'API_CASEV2_LOAD_MORE_SUCCESS';
export const SET_CASEV2_APPLIED_FILTERS_ARRAY = 'SET_CASEV2_APPLIED_FILTERS_ARRAY';
export const CASE_USER_AUTHORIZATION_SUCCESS = 'CASE_USER_AUTHORIZATION_SUCCESS';
export const CHANGE_SEARCH_TERM = 'CHANGE_SEARCH_TERM';
export const SET_CASE_AUTO_CORRECT_STATE = 'SET_CASE_AUTO_CORRECT_STATE';

const IS_USER_RESTRICTED_QUERY = {'query': ''};

const isProd = () => CONFIG.ENVIRONMENT == 'prod';

export const name = 'case';

const sources = [];
const initialState = {
  query: queryWithSearchParamsOnly(),
  results: [],
  totalCount: 0,
  loading: true,
  error: false,
  errorMessage: '',
  empty: true,
  filters: [],
  vignettesFilter: false,
  activeFilter: '',
  appliedQueryParams: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFilters: {}, // object ready for qs to convert to a string in string-mapper.js
  previousSearch: { query: { } },
  lastSearch: { query: { } },
  loadMorePending: false,
  pageCount: 0,
  currentPage: CONFIG.DEFAULT_CURRENT_PAGE,
  appliedFiltersArray: [],
  isUserRestricted: true,
  autoCorrectMode: true,
  autoCorrect: {
    isSearchTermCorrected: false,
    correctedSearchTerm: ''
  },
  currentLastResult: 0
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getCases: (state) => state[name].results,
  getTotalCount: (state) => state[name].totalCount,
  getError: (state) => state[name].error,
  getEmpty: (state) => state[name].empty,
  getQuery: (state) => state[name].query,
  getFilters: (state) => state[name].filters,
  getVignettesFilter: (state) => state[name].vignettesFilter,
  getActiveFilter: (state) => state[name].activeFilter,
  getAppliedFilters: (state) => state[name].appliedFilters,
  getAppliedQueryParams: (state) => state[name].appliedQueryParams,
  getLastSearch: (state) => state[name].lastSearch,
  getSorter: (state) => state[name].query[CONFIG.CASE_QUERY_PARAMS.SORTING_ORDER],
  getPageCount: (state) => state[name].pageCount,
  getLoadMoreStatus: (state) => state[name].loadMorePending,
  getCurrentPage: (state) => state[name].currentPage,
  getIsUserRestricted: (state) => state[name].isUserRestricted
};

const removeNullVignettesFromDoc = (doc) => doc.map((project) => ({ ...project, caseVignettes: project.caseVignettes.filter((e) => e.kpCmsId) }));

export const baseGenAIEnblRetrievalRequest = {
  'data_repository': 'elastic',
  'method': 'bm25',
  'from_n':1,
  'top_n': 20,
  'elastic_cluster': isProd() ? 'search_PROD' : 'search_QA',
  'consumer_options': {
    'consumer_key': 'case',
    'data_source': 'case',
    'workspace_id': isProd() ? 137 : 1301,
  },
  'search_options': {
    'filter_clauses': [],
    'advancesearch': [],
    'facets': []
  }
};

const positionSortOrderMapping = {
  'managing director and partner': 1,
  'partner and director': 2,
  'partner and associate director': 3,
  'associate director': 4,
  'partner': 5,
  'principal': 6,
  'project leader': 7,
  'consultant': 8,
  'associate': 9,
  'knowledge team': 10,
  'data & research services': 11,
  'pa mgt/operations': 12,
  'vs billable': 13,
  'human resources': 14,
  'temporary consulting team': 15,
  'platinion': 16,
  'bcg digital ventures': 17,
  'gamma': 18,
  'inverto': 19,
  'center for energy impact': 20,
  'information technology': 21,
  'summer consulting team': 22,
  'marketing': 23,
  'finance': 24,
  'operations': 25,
  'bcg brighthouse': 26,
  'expand': 27,
  'outside consulting team': 28,
  'bcg omnia': 29,
  'other consulting team': 30,
};

const mapGenAIEnblRetrievalResponse = (results) => {
  return results.map((item) => {
    return {
      docId: item.crawl_item_id,
      docRank: item.rank,
      //per Venkat Paranjothi - We don't have globalrelevance in the new elastic, so please keep the value 1 for now.
      globalrelevance: 1,
      modified: item.last_update_date,
      // source
      version: item.documentversion,
      officeName: item.office_name,
      caseNumber: item.case_number,
      companyId: item.client_id,
      officeCode: item.office_id,
      projectId: item.crawl_item_id,
      projectName: item.case_name,
      description: item.case_full_desc,
      industryAllPAs: item.allindustrypas?.map((pa) => {
        return({
          keywordTopic: pa.keywordtopic,
          topicId: pa.topic_id,
          topicNameAlias: pa.topic_name_alias,
          parentId: pa.parent_id,
          paRecommended: pa.key_material_flag,
          sort: pa.hierarchy_sort_order,
          fullPath: pa.path.replaceAll('>', '/')
        });
      }),
      functionalAllPAs: item.allfunctionalpas?.map((pa) => {
        return({
          keywordTopic: pa.keywordtopic,
          topicId: pa.topic_id,
          topicNameAlias: pa.topic_name_alias,
          parentId: pa.parent_id,
          paRecommended: pa.key_material_flag,
          sort: pa.hierarchy_sort_order,
          fullPath: pa.path.replaceAll('>', '/')
        });
      }),
      allSubjects: item.allsubjects?.map((subject) => {
        return({
          keywordTopic: subject.keywordtopic,
          topicId: subject.topic_id,
          topicNameAlias: subject.topic_name_alias,
          parentId: subject.parent_id,
          paRecommended: subject.key_material_flag,
          sort: subject.hierarchy_sort_order,
          fullPath: subject.path.replaceAll('>', '/')
        });
      }),
      shortDescription: item.case_short_desc,
      clientDescription: item.client_desc,
      caseHours: item.case_hours_by_staff,
      allCaseTeams: item.all_case_team?.map((person) => {
        return({
          hrEmployeeId: person.hremployeeid,
          firstName: person.firstname,
          lastName: person.lastname,
          // Per Venkat Paranjothi - This field is not in use.  Use HRID instead
          staffId: person.hremployeeid,
          email: person.email,
          isAlumni: person.isalumni,
          positionTitle: person.positiontitle,
          // 100 is default value to ensure end of order for positions not in mapping
          positionSortOrder: positionSortOrderMapping[person['position-as-of-title'].toLowerCase()] || 100,
          positionAsOfTitle: person['position-as-of-title'],
          timeAndBillingHours: item.case_hours_by_staff?.find((el) => el.hr_id == person.hremployeeid)?.tb_hours,
        });
      }),
      caseOpenDate: item.date_opened,
      caseCloseDate: item.date_closed,
      caseType: item.case_type,
      clientName: item.client_name,
      parentCompany: item.parent_client_name,
      clientBU: item.clientbu,
      caseVignettes: item.casevignettes,
      caseVignettesStatus: item.casevignettesstatus,
      kmsOwner: item.kms_owner,
      cco: item.cco,
      relatedCases: item.related_cases,
      region: item.region,
      geoRegion: item.georegion,
      textDescription: item.case_full_desc,
      clientBUDescription: item.client_bu_desc,
      subjectPAFilter: item.subjectpatopic.join('; '),
      knowledgeAssets: item.knowledgeassets
    };
  });
};

const checkVignettes = (filters) => {
  if (filters.find(
    (filter) => filter.parameterName === CONFIG.CASE_FILTERS.VIEW_CONTROLS.VIGNETTES.NAME && filter.refinerValues)
    ?.refinerValues.find((value) => value.display === 'True')) {
    return true;
  }
  return false;
};

export const actions = {
  caseSearch: (reload, query) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    const searchQuery = flattenQuery(query);

    dispatch({
      type: API_CASEV2_SEARCH_PENDING,
      payload: {
        reload,
        query: searchQuery
      }
    });

    const hasParenthesis = (element) => element.startsWith('(') && element.endsWith(')');

    let searchQuery2 = CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API ? {...searchQuery, ...baseGenAIEnblRetrievalRequest} : searchQuery;
    const advanceSearchValue = searchQuery[CONFIG.CASE_QUERY_PARAMS.ADV_SEARCH];
    const searchQueryValue = searchQuery.query;
    if (advanceSearchValue && searchQueryValue && !searchQuery[CONFIG.CASE_QUERY_PARAMS.ADV_SEARCH].some(hasParenthesis)) {
      // For case search v2, surround the searchTerm by a pair of parenthesis, when adVSearch is involved.  If not, the backend API is not taking advSearch parameters seriously.
      // This code hides the parenthesis from the user.
      searchQuery2 = {...searchQuery};
      searchQuery2[CONFIG.CASE_QUERY_PARAMS.QUERY] = `(${searchQuery.query})`;
    }

    try {
      const data = await axios.post(CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API ? CONFIG.API_URL.GENAI_ENBL_RETRIEVAL('') : CONFIG.API_URL.CASE_SEARCH_V2(''),
        searchQuery2, 
        {
          cancelToken: newSource.token 
        }
      );

      const filtersFromAPIAndConfig = formatFilters(data?.refiners, CONFIG.CASE_FILTERS.FILTER_BAR);

      const vignettesFilter = data?.refiners ? checkVignettes(data.refiners) : false;
      
      const payload  = {
        data: {
          ...data,
          doc: CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API ? mapGenAIEnblRetrievalResponse(data.results) : removeNullVignettesFromDoc(data.doc),
          TotalCount: CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API ? data.number_of_results : data.TotalCount
        },
        query: searchQuery,
        filters: filtersFromAPIAndConfig,
        vignettesFilter,
        autoCorrect: {
          isSearchTermCorrected: false,
          correctedSearchTerm: ''
        }
      };
      if (!CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API && data.didYouMeans && Array.isArray(data.didYouMeans)) {
        payload.autoCorrect.isSearchTermCorrected = data.didYouMeans.length > 0;
        payload.autoCorrect.correctedSearchTerm  = data.didYouMeans.reduce(
          (accumulator, currentValue) => {
            return accumulator.replace(new RegExp(`(${escapeRegExp(currentValue.form)})`, 'g') , currentValue.correction);
          },
          query.query
        );
      }

      dispatch({
        type: API_CASEV2_SEARCH_SUCCESS,
        payload
      });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_CASEV2_SEARCH_FAILURE, payload: error.toString() });
      throw error;
    }
  },
  loadMoreCases: (pageNumber, query, currentLastResult) => async (dispatch) => {
    if (CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API){
      query = {...query, ...baseGenAIEnblRetrievalRequest};
      query.from_n = currentLastResult + 1;
    }
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    const loadMoreQuery = flattenQuery(query);
    loadMoreQuery.resultsFromPage = parseInt(pageNumber, 10) + CONFIG.DEFAULT_CURRENT_PAGE;

    dispatch({
      type: API_CASEV2_LOAD_MORE_PENDING,
      payload: {
        query: loadMoreQuery
      }
    });

    let loadMoreQuery2 = loadMoreQuery;
    if (loadMoreQuery[CONFIG.CASE_QUERY_PARAMS.ADV_SEARCH] && loadMoreQuery.query && !loadMoreQuery.startsWith('(') && !loadMoreQuery.endsWith(')')) {
      // For case search v2, surround the searchTerm by a pair of parenthesis, when adVSearch is involved.  If not, the backend API is not taking advSearch parameters seriously.
      // This code hides the parenthesis from the user.
      loadMoreQuery2 = {...loadMoreQuery};
      loadMoreQuery2[CONFIG.CASE_QUERY_PARAMS.QUERY] = `(${loadMoreQuery.query})`;
    }

    try {
      const data = await axios.post(
        CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API ? CONFIG.API_URL.GENAI_ENBL_RETRIEVAL('') : CONFIG.API_URL.CASE_SEARCH_V2(''),
        loadMoreQuery2, 
        {
          cancelToken: newSource.token
        }
      );

      if (data) {
        dispatch({
          type: API_CASEV2_LOAD_MORE_SUCCESS,
          payload: {
            data: {
              ...data,
              doc: CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API ? mapGenAIEnblRetrievalResponse(data.results) : removeNullVignettesFromDoc(data.doc)
            },
            query: loadMoreQuery
          }
        });
      } else {
        throw new Error('no results returned');
      }
    } catch (error) {
      dispatch({ type: API_CASEV2_LOAD_MORE_FAILURE, payload: error.message });
      throw error;
    }
  },
  authorizeCaseUser: () => async (dispatch) => {
    try {
      const data = await axios.post(CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API ? CONFIG.API_URL.GENAI_ENBL_RETRIEVAL('') : CONFIG.API_URL.CASE_SEARCH_V2(''), IS_USER_RESTRICTED_QUERY);
      const result = (data?.doc[0]?.clientName === CONFIG.CASE_RESTRICTED);
      const isUserRestricted = result == null ? true : result;
      dispatch({ type: CASE_USER_AUTHORIZATION_SUCCESS, payload: { isUserRestricted } });
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('User Authorization via Cases failed', ex.stack);
    }
  },
  changeSorter: (payload) => async (dispatch) => dispatch({ type: CHANGE_CASEV2_SORTER, payload }),
  setActiveFilter: (filterName) => async (dispatch) => dispatch({ type: SET_CASEV2_ACTIVE_FILTER_INDEX, payload: filterName }),
  setAppliedFilters: (queryParams) => setAppliedFilters(queryParams, CONFIG.CASE_FILTERS.FILTER_BAR, SET_CASEV2_APPLIED_FILTERS_PENDING, SET_CASEV2_APPLIED_FILTERS_SUCCESS, SET_CASEV2_APPLIED_FILTERS_FAILED),
  setAutoCorrectMode: (payload) => async (dispatch) => dispatch({ type: SET_CASE_AUTO_CORRECT_STATE, payload })
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        query: queryWithSearchParamsOnly()
      };
    case CHANGE_SEARCH_TERM: // dispatched from type-ahead
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: action.payload
        }
      };
    case API_CASEV2_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        empty: false,
        query: action.payload.query,
        previousSearch: state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case API_CASEV2_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_CASEV2_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload.data === '' ? [] : action.payload.data.doc,
        empty: action.payload.data === '' ? true : action.payload.data.doc?.length <= 0,
        totalCount: action.payload.data === '' ? 0 : action.payload.data?.TotalCount || 0,
        filters: action.payload.filters === '' ? [] : action.payload.filters,
        vignettesFilter: action.payload.vignettesFilter,
        previousSearch: state.previousSearch.query?.query ? state.previousSearch : state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        },
        pageCount: action.payload.data === '' ? 0 : action.payload.data.PageCount,
        currentPage: action.payload.data === '' ? CONFIG.DEFAULT_CURRENT_PAGE : action.payload.data.CurrentPage,
        currentLastResult: action.payload.data.doc?.length,
        autoCorrect: action.payload.autoCorrect
      };
    case SET_CASEV2_ACTIVE_FILTER_INDEX:
      return {
        ...state,
        activeFilter: action.payload
      };
    case SET_CASEV2_APPLIED_FILTERS_PENDING:
      return {
        ...state,
        appliedFilters: {}
      };
    case SET_CASEV2_APPLIED_FILTERS_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload.appliedFilterAndQueryParams
      };
    case SET_CASEV2_APPLIED_FILTERS_FAILED:
      return {
        ...state,
        appliedFiltersError: true,
        appliedFiltersErrorMsg: action.payload.errorMessage
      };
    case CHANGE_CASEV2_SORTER:
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.CASE_QUERY_PARAMS.SORTING_ORDER]: action.payload
        }
      };
    case API_CASEV2_LOAD_MORE_PENDING:
      return {
        ...state,
        loadMorePending: true,
        error: false,
        errorMessage: '',
        empty: false
      };
    case API_CASEV2_LOAD_MORE_FAILURE:
      return {
        ...state,
        loadMorePending: false,
        error: true,
        errorMessage: action.payload
      };
    case API_CASEV2_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMorePending: false,
        pageCount: action.payload.data.PageCount,
        results: state.results.concat(action.payload.data.doc),
        currentPage: action.payload.data.CurrentPage,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        },
        currentLastResult: state.currentLastResult + action.payload.data.doc?.length,
      };
    case CASE_USER_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        isUserRestricted: action.payload.isUserRestricted
      };
    case SET_CASE_AUTO_CORRECT_STATE:
      return {
        ...state,
        autoCorrectMode: action.payload
      };
    default:
      return state;
  }
}
